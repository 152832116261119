
                import Handlebars from 'handlebars/dist/handlebars.runtime';
                const templateFunction = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n<li class=\"smallCard animate__animated animate__zoomInUp\" data-id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":3,"column":67},"end":{"line":3,"column":73}}}) : helper)))
    + "\">\n    <img class=\"smallCard-small\" src='"
    + alias4(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"image") : depth0)) != null ? lookupProperty(stack1,"url") : stack1), depth0))
    + "' data-id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":4,"column":62},"end":{"line":4,"column":68}}}) : helper)))
    + "\" width=200/>\n    <div class=\"smallCard_info\">\n        <ul class=\"smallCard_info_list\">\n            <li class=\"smallCard_heroes\">\n                <p class=\"smallCard_text\" data-id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":8,"column":51},"end":{"line":8,"column":57}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":8,"column":59},"end":{"line":8,"column":67}}}) : helper)))
    + "</p>\n            </li>\n            <li class=\"smallCard_date\">\n                <p class=\"smallCard_text\" data-id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":11,"column":51},"end":{"line":11,"column":57}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"localDate") || (depth0 != null ? lookupProperty(depth0,"localDate") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"localDate","hash":{},"data":data,"loc":{"start":{"line":11,"column":59},"end":{"line":11,"column":72}}}) : helper)))
    + "</p>\n            </li>\n            <li class=\"smallCard_place\">\n                <a href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"map") || (depth0 != null ? lookupProperty(depth0,"map") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"map","hash":{},"data":data,"loc":{"start":{"line":14,"column":25},"end":{"line":14,"column":32}}}) : helper)))
    + "\" class=\"smallCard_text_place smallCard_href\" target=\"_blank\" data-id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":14,"column":103},"end":{"line":14,"column":109}}}) : helper)))
    + "\"><span>"
    + alias4(((helper = (helper = lookupProperty(helpers,"placeName") || (depth0 != null ? lookupProperty(depth0,"placeName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"placeName","hash":{},"data":data,"loc":{"start":{"line":14,"column":117},"end":{"line":14,"column":130}}}) : helper)))
    + "</span></a>\n            </li>\n        </ul>\n    </div>\n</li>\n\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":20,"column":9}}})) != null ? stack1 : "")
    + "\n\n\n";
},"useData":true});
                export default templateFunction;
                